import AppRecord from './AppRecord.js'
import APP from '../../main.js'

class User extends AppRecord {
  get person(){
    return APP.$store.state.people.find(per => per.id === this.person_id)
  }
  get locked(){
    return this.locked_at||this.hard_locked_at ? true : false
  }
  set locked(v){
    if(v){
      this.locked_at = (new Date()).toISOString()
    } else {
      this.locked_at = null
    }
  }
  get hard_locked(){
    return this.hard_locked_at ? true : false
  }
  set hard_locked(v){
    if(v){
      this.hard_locked_at = (new Date()).toISOString()
    } else {
      this.hard_locked_at = null
    }
  }
  get person_name(){
    if(this._person_name){return this._person_name}
    if(this.person){
      this._person_name = this.person.name
      return this._person_name
    } else {
      return null
    }
  }

  get is_client(){
    return ['Client','Administrator'].includes(this.person_type)
  }

  get is_franchise(){
    return this.franchise_id
  }
  
  get isEmployee(){
    return ['Employee'].includes(this.person_type)
  }

  get isAdmin(){
    return this.roles.includes('admin')
  }

}

export default User
