import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import User from "./models/User.js";
import axe from "./modules/axios_status.js";

export const store = new Vuex.Store({
  state: {
    state_current_user: { id: undefined, roles: [] },
    users: [],
    applicationLogSource: null,
    selectEntityFormShown: false,
    selectAccountFormShown: false,
    selectPersonFormShown: false,
    selectIssueFormShown: false,
    selectMenuFormShown: false,
    loginRedirectPath: null,
    documentNoteSource: null,
    reportData: null
  },
  getters: {
    current_user(state) {
      // Point to state_Current_user, which is set along localStorage, this
      // ensures re-run, fetch of localStorage upon set user. Otherwise the getter
      // wil return last value.
      state.state_current_user;
      let usr = JSON.parse(localStorage.getItem('current_user'))||{id: undefined, roles: []}
      if (usr.id) {
        let res = state.users.find(us => {
          if (us){
            us.id === usr
          }
        })
        if (res) {
          return res;
        }
      }
      return new User(usr);
    },
    current_user_is_employee(state, getters) {
      if (
        getters.current_user.roles.includes("employee") ||
        getters.current_user.roles.includes("aep")
      ) {
        return true;
      } else {
        return false;
      }
    },
    current_user_is_investor(state, getters) {
      if (getters.current_user.roles.includes("client")) {
        return true;
      } else {
        return false;
      }
    },
    sign_in_path(state, getters) {
      state;
      if (
        getters.current_user.roles.includes("employee") ||
        getters.current_user.roles.includes("aep")
      ) {
        return { path: "/employee/crm/clients" };
      } else if (getters.current_user.roles.includes("client")) {
        return { path: "/investor" };
      } else {
        return { path: "/" };
      }
    },
  },
  mutations: {
    SET_CURRENT_USER(state, payload) {
      // Ensure to set state_current_user upon setting localStorage, the getter
      // will point to state_current_user, thus refetch localStragae when changed
      // in state. localStorage is needed to persist current_user after page
      // refresh or browser shutdown
      state.state_current_user = payload;
      localStorage.setItem("current_user", JSON.stringify(payload));
    },
    showApplicationLog(state, payload) {
      state.applicationLogSource = payload;
    },
    showDocumentNoteForm(state, payload) {
      state.documentNoteSource = payload;
    },
    showSelectEntityForm(state, payload) {
      state.selectEntityFormShown = payload;
    },
    showSelectAccountForm(state, payload) {
      state.selectAccountFormShown = payload;
    },
    showSelectPersonForm(state, payload) {
      state.selectPersonFormShown = payload;
    },
    showSelectIssueForm(state, payload) {
      state.selectIssueFormShown = payload;
    },
    showSelectMenuForm(state, payload) {
      state.selectMenuFormShown = payload;
    },
    loginRedirectPath(state, payload) {
      state.loginRedirectPath = payload;
    },
    reportingData(state, payload) {
      state.reportData = payload;
    },
  },
  actions: {},
  modules: {
    axe,
  },
});
