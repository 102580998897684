<template>
  <div id='app'>
    <notifications transition-name="notification-list" transition-mode="out-in"></notifications>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {}
</script>

<style>
</style>
