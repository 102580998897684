// Imports
import Vue from 'vue'
import './pollyfills'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { store } from './store/store'
import App from './App.vue'

// Language: i18
import lang from 'element-ui/lib/locale/lang/da'
import locale from 'element-ui/lib/locale'
locale.use(lang)

// Vue config, plugin setup
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Fix grainy/blurred popover
// import Popper from 'popper.js';
// Popper.Defaults.modifiers.computeStyle.enabled = false;
// Popper.Defaults.modifiers.computeStyle.gpuAcceleration = !(window.devicePixelRatio < 1.5 && /Win/.test(navigator.platform))
// Popper.Defaults.modifiers.computeStyle.gpuAcceleration = true;

// Searchable select
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

// Vue notification
import VueNotify from 'vue-notifyjs'
Vue.use(VueNotify, {type: 'primary', timeout: 6000, horizontalAlign: 'right', verticalAlign: 'top'})

// charts
import 'zingchart/es6';
import zingchartVue from 'zingchart-vue';
Vue.component('zingchart', zingchartVue);

// underscore js
import underscore from 'vue-underscore';
Vue.use(underscore);

// styling
import 'sweetalert2/dist/sweetalert2.css'
import 'vue-notifyjs/themes/default.css'
import './assets/app.scss'

// Validation
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import da from "vee-validate/dist/locale/da.json";
import * as rules from "vee-validate/dist/rules";
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
extend("businessDay", { // Validation if a given date is a weekday
  validate(value) {
    const date = new Date(value);
    return date.getDay() !== 6 && date.getDay() !== 0 ; // Returns true if the day is Monday to Friday
  },
  message: "Datoen skal være en bankdag (mandag til fredag)."
});

localize("da", da);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// Router
import VueRouter from 'vue-router'
import VueRouterPrefetch from 'vue-router-prefetch'
import initProgress from './progressbar';
import routes from './routes/routes'
Vue.use(VueRouter)
Vue.use(VueRouterPrefetch)

// Shortcut keys
Vue.use(require('vue-shortkey'))

const router = new VueRouter({
  routes, // short for routes: routes
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: ()=>{
    return false
  },
  // scrollBehavior: (to,from)=>{return false},
  // scrollBehavior: (to, from) => {
  //   //return savedPosition
  //   if (to.name === from.name) {
  //     return false
  //   } else {
  //     return { x: 0, y: 0 }
  //   }
  // }
})
initProgress(router);

// SCROLLING
import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

// Custom imports
// import './prototypes.js';
Date.prototype.to_s = function(){
  return this.getFullYear()+'-'+(this.getMonth()+1).pad(2)+'-'+this.getDate().pad(2)
}
Date.prototype.lastDateInMonth = function(){
  return new Date(this.getFullYear(), this.getMonth()+1, 0);
}
Date.prototype.to_da = function(){
  return this.getDate().pad(2)+'-'+(this.getMonth()+1).pad(2)+'-'+this.getFullYear()
}
Date.prototype.getWeekNumber = function(){
  var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
  var dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
}
Number.prototype.pad = function(size) {
  var s = String(this)
  while (s.length < (size || 2)) {s = "0" + s;}
  return s;
}
Number.prototype.round = function(digits){
  return Math.round(this*10**digits)/10**digits
}
Array.prototype.last = function(){
  return this[this.length - 1]
}
Array.prototype.first = function(){
  return this[0]
}
Array.prototype.sortBy = function(attr){
  return this.sort((a,b) => (a[attr] > b[attr]) ? 1 : -1)
}
Array.prototype.isEmpty = function(){
  return this.length === 0
}
String.prototype.reverse = function(){
  return this.split('').reverse().join('')
}
String.prototype.capitalize = function(){
  return this.charAt(0).toUpperCase() + this.slice(1)
}
String.prototype.camelize = function(){
  return this.split('_').map(s => s.capitalize()).join('')
}
Array.prototype.mean = function(){
  if(this.length > 0){
    return this.reduce((a,b)=>{return a+b}) / parseFloat(this.length)
  } else {
    return NaN
  }
}
Array.prototype.sample_variance = function(){
  if(this.length > 2){
    let m = this.mean()
    let sv = 0
    this.forEach(v => sv += (v-m)**2)
    return sv/(this.length - 1.0)
  } else {
    return NaN
  }
}
Array.prototype.standard_deviation = function(){
  if(this.length > 2){
    return this.sample_variance()**0.5
  } else {
    return NaN
  }
}

import axiosInstance from './store/axiosInstance'
Vue.prototype.$axios = axiosInstance

import 'regenerator-runtime';

// Create palette
Vue.prototype.$palette = function(n, names=null){
  const colors = {
    maroon:       [180,  0, 65],
    deep_maroon:  [135,  0, 50],
    dark_maroon:  [ 85,  0, 35],
    light_orange: [235,130, 70],
    orange:       [200, 90, 35],
    dark_orange:  [135, 45,  5],
    light_green:  [125,170,100],
    green:        [ 75,115, 65],
    dark_green:   [ 30, 60, 30],
    light_blue:   [150,150,245],
    blue:         [ 75, 65,190],
    dark_blue:    [ 40, 30,100],
    // ALIASES
    'Kontant':               [150,150,245],
    'Obligationer':          [ 75, 65,190],
    'Aktier':                [135, 45,  5],
    'Hedgefonde':            [200, 90, 35],
    'Finansiering':          [235,130, 70],
    'Ejendomme':             [125,170,100],
    'Private Equity':        [ 75,115, 65],
    'Infrastruktur':         [ 30, 60, 30],
    'Multi Strategi':        [180,  0, 65],
    'Direkte investeringer': [135,  0, 50],
    'Honorar':               [ 80,  0, 35],
  }
  if(names){
    return names.map((nn)=>{
      return `rgb(${colors[nn].join(',')})`
    })
  }
  if(n==1){
    names = ['dark_maroon']
  } else if(n==2){
    names = ['dark_maroon','orange']
  } else if(n==3){
    names = ['dark_maroon','orange','green']
  } else if(n==4){
    names = ['dark_maroon','orange','green','blue']
  }
  if(names){
    return names.map((nn)=>{
      return `rgb(${colors[nn].join(',')})`
    })
  }
  if(n <= Object.values(colors).length){
    let cols = Object.values(colors).slice(0,n)
    return cols.map((nn)=>{
      return `rgb(${nn.join(',')})`
    })
  }
  // Ensure returned value is between 0 and 255, and is an integer
  const formatNumber = function(x){
    return Math.max(Math.min(Math.round(x), 255), 0)
  }
  // Define "splines" for each color code on x rangeing from 0-1
  const curveR = function(x){
    let y = 6575*x**4 - 14168*x**3 + 9767*x**2 -2165.8*x +169.2
    return formatNumber(y)
  }
  const curveG = function(x){
    let y = 6239.1*x**4-12994*x**3+8338.2*x**2-1722.6*x+151.22
    return formatNumber(y)
  }
  const curveB = function(x){
    let y = 415.22*x**2-566.76*x+221.18
    return formatNumber(y)
  }
  // Return 'n' color codes wheres for n=1 we return at x=0.5, n=2 => x={0.33, 0.66} etc.
  let increment = 1.0/(n+1)
  let res = []
  var pos
  for(var i = 0; i < n; i++){
    pos = increment*(i+1)
    res.push(`rgb(${curveR(pos)},${curveG(pos)},${curveB(pos)})`)
  }
  return res.reverse()
},

Vue.config.productionTip = false

// Function to switch between investor and employee paths. WHen currently on investor
// a router name will be prepended with investor. THus assuming two named paths
// exists, investor_accoutns and accounts use
// <router-link :to="{name: $router_name('accounts')}"
// To let the app resolve the right route
Vue.prototype.$resolveRoute = function(name){
  if(this.$onInvestorRoute()){
    return 'investor_'+name
  } else {
    return name
  }
}
Vue.prototype.$onInvestorRoute = function(){
  return this.$route.path.slice(0,9) === '/investor'
}
Vue.prototype.$onEmployeeRoute = function(){
  return this.$route.path.slice(0,9) === '/employee'
}

// Import filters
import './filters.js'

// Have a container as global eventbus to handling scroll and toggle, as well
// as other global events
Vue.prototype.$events = new Vue()

const APP = new Vue({
  render: h => h(App),
  router,
  store,
  created(){
    this.$events.$on("scroll", (event)=>{
      this.$nextTick(()=>{
        this.$scrollTo('#'+event, 1800, {
          container: "body",
          easing: "ease",
          offset: -65,
          force: true,
          cancelable: true,
          onStart: false,
          onDone: false,
          onCancel: false,
          x: false,
          y: true
        })
      })
    })
  },
}).$mount('#app')
export default APP
