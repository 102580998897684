const state = {
	ax_status: []
}

const getters = {

}

const mutations = {
	AX_ADD_STATUS(state,record){
		state.ax_status.unshift(record)
	},
	AX_POP_STAT(state,id){
		let index = state.ax_status.findIndex(item => item.id === id)
		if(index > -1){
			state.ax_status.splice(index, 1)
		}
	},
	AX_UPDATE_STATUS(state, payload){
		const item = state.ax_status.find(item => item.id === payload.id)
		if(item){
			Object.assign(item, payload);
		}
	},
}
const actions = {
	AX_ADD_STATUS({commit,dispatch}, payload){
		let promise = payload.promise
		let text = payload.text
		let id = Math.random().toString(36).substring(7)
		let record = {
			id: id,
			text: text,
			collecting: true,
			collected: false,
			error: false,
			length: undefined,
		}
		commit("AX_ADD_STATUS", record)
		promise.then((res)=>{
			dispatch("AX_RESOLVE_STATUS", {response: res, id: id})
		}).catch((err)=>{
			dispatch("AX_RESOLVE_STATUS", {response: err, id: id})
			commit("AX_UPDATE_STATUS", {id: id, error: true})
		})
		setTimeout(()=>{
			commit("AX_POP_STAT", id)
		}, 20000)
	},
	AX_RESOLVE_STATUS({commit},payload){
		let response = payload.response
		let id = payload.id

		/* eslint-disable no-prototype-builtins */
		let length = response.hasOwnProperty('data') ? response.data.length : undefined

		let mod = {
			id: id,
			collecting: false,
			collected: true,
			length: length,
		}
		commit("AX_UPDATE_STATUS", mod)
	},
}

export default {
	state,
	getters,
	mutations,
	actions
}

// issues: {
// 	collecting: false,
// 	collected: false,
// 	text: 'værdipapirer',
// 	length: undefined,
// 	// then =>
// 		// this.statuses.ownerships.length = res.count
// 		// this.statuses.ownerships.collected = true
// 		// this.statuses.ownerships.collecting = false
// },
