import axios from 'axios'
import APP from '../main.js'
import Qs from 'qs';
import envBaseURl  from './config/localhost.js'

const axiosInstance = axios.create({
  baseURL: envBaseURl,
  withCredentials: true,
  xsrfCookieName: "CSRF-TOKEN",
  xsrfHeaderName: "X-CSRF-Token",
})

axiosInstance.interceptors.request.use((config)=>{
  config.headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    ...config.headers,
  }
  config.paramsSerializer = params => {
    // Qs is already included in the Axios package
    return Qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false
    })
  }
  return config
})

axiosInstance.interceptors.response.use((response)=>{
  return response
}, (error)=>{
  // Check if custom error handling is required
  // Send handlelocally: true to you Axios call to disable default messages
  if (error.config && error.config.handleLocally) {
    return Promise.reject(error);
  }

  if( (error.response) && (error.response.status === 401) ){
    // At error 401 or no response (CORS REJECTION),
    let ignore_paths = [
      envBaseURl+'/login',
      envBaseURl+'/ping',
      envBaseURl+'/update_password',
      envBaseURl+'/unlock',
    ]
    if(!ignore_paths.includes(error.request.responseURL)){
      APP.$notify({
        icon:    'fa fa-times',
        duration: 12000,
        type:    'danger',
        title:   'Adgang nægtet',
        message: 'Du har ingen rettigheder til denne ressource og vil blive logget af.',
      })
      APP.$axios.delete('/logout')
      APP.$store.dispatch("RESET_STATE")
      APP.$store.commit("SET_CURRENT_USER", null)
      APP.$router.push({name: 'login'})
    }
  } else if ((error.response) && (error.response.status === 403)) {
    // Forbidden resource
    APP.$notify({
      icon:    'fa fa-times',
      duration: 12000,
      type:    'danger',
      title:   'Rettighed afvist',
      message: 'Du har ingen rettigheder til denne ressource.',
    })
  } else if (error.response && error.response.status === 422) {
    // At 422 server failure on update
    APP.$notify({
      icon:  'fa fa-times',
      type:  'danger',
      timeout: 45000,
      title: "Opdatering fejlede",
      message: "En eller flere fejl forhindrede opdatering",
    })
    let response = error.response
    for(let key in response.data){
      APP.$notify({
        icon:    'fa fa-times',
        timeout: 45000,
        type:    'warning',
        title:   key,
        message: response.data[key].join('. '),
      })
    }
  } else if (error.response && error.response.status === 500) {
    // At 500 server failure
    APP.$notify({
      icon:    'fa fa-times',
      duration: 12000,
      type:    'danger',
      title:   'Serverfejl!',
      message: 'En fejl på serveren forhindrede forespørgsel, kontakt administrator for at få problemet løst.',
    })
  } else {
    if (error && error.response){
      APP.$notify({
        icon:    'fa fa-times',
        duration: 12000,
        type:    'warning',
        title:   error.response.status + ' ' + error.response.statusText,
      })
    }
  }

  return Promise.reject(error)
})

export default axiosInstance
