import Vue from 'vue'

Vue.filter('date', function (value) {
  if (!value) return ''
  return value.split('-').reverse().join('&#8209;')
})
Vue.filter('round', function (value, digits) {
  return Math.round(parseFloat(value)*10**digits)/10**digits
})
Vue.filter('percentage', function (value, opts={}) {
  if(!value && value!=0){return ''}
  return value.toLocaleString('da-dk', Object.assign(opts, {style: 'percent'}))
})
Vue.filter('percentageNoZero', function (value, opts={}) {
  if(!value || (value && value.round(10) == 0)){return ''}
  return value.toLocaleString('da-dk', Object.assign(opts, {style: 'percent'}))
})
Vue.filter('localized', function (value, opts={}) {
  if(!value && value!=0){return ''}
  return value.toLocaleString('da-dk', opts)
})
Vue.filter('localizedNoZero', function (value, opts={}) {
  if(!value || (value && value.round(10) == 0)){return ''}
  return value.toLocaleString('da-dk', opts)
})
Vue.filter('datetime', function (value) {
  if(!value){return ''}
  let time = new Date(value)
  return `${time.toISOString().slice(0,10).split('-').reverse().join('&#8209;')}&nbsp;${time.toLocaleTimeString().split('-').join('&#8209;')}`
})
Vue.filter('datetime_br', function (value) {
  if(!value){return ''}
  let time = new Date(value)
  return `${time.toISOString().slice(0,10).split('-').join('&#8209;')}<br>${time.toLocaleTimeString().split('-').join('&#8209;')}`
})
Vue.filter('byte', function (value) {
  if(!value){return '0B'}
  if(value <= 1024){
    return value+'B'
  } else if(value <= 1024**2){
    return Math.ceil(value/1024**1)+'kB'
  } else if(value <= 1024**3){
    return Math.round((value/1024**2)*100)/100+'MB'
  } else if(value <= 1024**4){
    return Math.round((value/1024**3)*100)/100+'GB'
  } else if(value <= 1024**5){
    return Math.round((value/1024**4)*100)/100+'TB'
  } else if(value <= 1024**7){
    return Math.round((value/1024**5)*100)/100+'PB'
  } else {
    return Math.round((value/1024**6)*100)/100+'EB'
  }
})
