import APP from '../../main.js'
import Vue from 'vue'

class AppRecord {
  constructor(rec){
    this.update(rec)
  }
  update(data_hash){
    for(let [key, value] of Object.entries(data_hash)) {
      Vue.set(this, key, value)
    }
    this.resetMomerizedComputed()
  }
  resetMomerizedComputed(){}
  get created_days_ago(){
    let cre = Date.parse(this.created_at)
    let now = Date.now()
    return (now-cre)/1000/60/60/24
  }
  get created_days_ago_string(){
    return this.daysAgoToString(this.created_days_ago)
  }
  get updated_days_ago(){
    let cre = Date.parse(this.updated_at)
    let now = Date.now()
    return (now-cre)/1000/60/60/24
  }
  get updated_days_ago_string(){
    return this.daysAgoToString(this.updated_days_ago)
  }
  daysAgoToString(days){
    if(days < 1){
      return "Inden for en dag"
    } else if(days < 2){
      return "I går"
    } else if(days < 366){
      return `${Math.floor(days)} dage siden`
    } else {
      let years = Math.floor(days / 365)
      let remainder = days - (years*365)
      let months = Math.floor(remainder / 30.5)
      remainder = remainder - (months*30.5)
      let rdays = Math.ceil(remainder)
      return `${years} år ${months} måneder og ${rdays} dage siden`
    }
  }
  setRelatedData(items, controller){
    items.forEach((item) => {
      APP.$store.state.controllers[controller].changeDatum(item)
    })
  }
  // Date parser and date setter to allow form input to set date value as
  // string %Y-%m-%d when format is valid and after 1900-01-01, i.e. when
  // input is complete
  parseDateString(date){
    let match = (date||'').match(/^\d{4}-\d{2}-\d{2}$/)
    if(match){
      let int = Date.parse(date)
      if(int && (int > -2208988800000)){
        let date_string = (new Date(int)).to_s()
        return date_string
      }
    }
    return null
  }
  setDateFromInput(date_string, key){
    if(date_string){
      let date = this.parseDateString(date_string)
      if(date){
        let res = {}
        res[key]=date
        this.update(res)
      }
    } else {
      let res = {}
      res[key]=date_string
      this.update(res)
    }
  }
  get controller_name(){
    return this.constructor_name+'sController'
  }
  get controller(){
    return APP.$store.state.controllers[this.controller_name]
  }
  get metaData(){
    return ((this.controller||{})._description||{})
  }
  translateColumnName(col){
    return ((this.metaData.columns||{})[col]||{}).title||col
  }

  isFieldEnum(fieldName){
    return ((this.metaData.enums||{})[fieldName]||null) != null
  }

  // Readable tag_lsit if on model
  get tagListReadable(){
    if (!this.tag_list){
      return null
    }

    // Convert tagl_list from array to commaseparated string
    return this.tag_list.join(', ')
  }
}

export default AppRecord
